//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.ant-modal-body::-webkit-scrollbar {
  display: none;
  scrollbar-width: none !important;
}
